import React from "react"
import loadable from "@loadable/component"

import Layout from "../components/Layout"
import geoJson from "../data/nhs-boundaries-2020.json"
import initialData from "../api-data/hospitalCases-nhsregion.json"

const BaseMap = loadable(() => import("../components/BaseMap"))

const PatientsMap = ({ location }) => (
  <Layout pathname={location.pathname} title={"Patients in hospital map"}>
    <BaseMap
      initialData={initialData}
      apiPath="/healthcare/hospitalCases/nhsregion"
      label="patients in hospital"
      geoJson={geoJson}
      codeProp="nhser20cd"
      nameProp="nhser20nm"
      bounds={[
        [49.5, -6.1],
        [56.5, 1.7],
      ]}
    />
  </Layout>
)

export default PatientsMap
